import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import userIcon from "../image/user-icon.svg";

import Footer from "./footer";
import Header from "./header";
import { Row, Col } from "react-bootstrap";
import { ReserveDto } from "../entity/reserve-dto";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import moment from "moment";
import { confirmReserveDto } from "../redux/reservationSlice";
import { Link, useNavigate } from "react-router-dom";
import "../scss/pages/home.scss";
import { logout } from "../redux/authSlice";
import {
  TYPE_OF_ARRIVAL_FROM_AIRPORT,
  TYPE_OF_DEPART_TO_AIRPORT,
  TYPE_OF_POINT_TO_POINT,
} from "../entity/orderType";
import logo from "../image/icon-white.svg";
import backgroundImage from "../image/bg-breadcrumb.png";
import classNames from "classnames";
import AOS from "aos";
import GoogleMapAutoCompleteTextField from "./booking/GoogleMapAutoCompleteTextField";
import { isAtHongKongAirport } from "./util/constant";
import HomeNewPage from "./home_new";

export default function HomePage() {
  const [passengerName, setPassengerName] = useState("");
  const [passengerEmail, setPassengerEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [locationFrom, setLocationFrom] = useState("");
  const [displayFlightNo, setDisplayFlightNo] = useState(false);
  const [flightNo, setFlightNo] = useState("");

  const [locationTo, setLocationTo] = useState("");
  const [passengerNum, setPassengerNum] = useState(1);
  const [luggNum, setLuggNum] = useState(0);
  const [validated, setValidated] = useState(false);
  const locationFromRef = useRef<HTMLInputElement>(null);
  const locationToRef = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState(0);
  const [locationToPlace, setLocationToPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();
  const [locationFromPlace, setLocationFromPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();
  const updateLocationFrom = (place: google.maps.places.PlaceResult) => {
    setLocationFrom(place.name!);
    setLocationFromPlace(place);
  };
  const updateLocationTo = (place: google.maps.places.PlaceResult) => {
    setLocationTo(place.name!);
    setLocationToPlace(place);
  };

  useEffect(() => {
    console.log("key: " + key);
    setKey((prevKey) => prevKey + 1);
  }, []);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clickLogout = async () => {
    let params = new URLSearchParams();
    const response = await fetch("/logout", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "include",
      body: params,
    });
    window.location.href = "/login/oauth2";
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      locationFrom.toLowerCase().includes("airport") ||
      locationTo.toLowerCase().includes("airport") ||
      locationFrom.toLowerCase().includes("機場") ||
      locationTo.toLowerCase().includes("機場")
    ) {
      setDisplayFlightNo(true);
    } else {
      setDisplayFlightNo(false);
      setFlightNo("");
    }
  }, [locationFrom, locationTo]);

  useEffect(() => {
    setPassengerName(currentUser?.displayName!);
  }, [currentUser]);

  const clickSubmitButton = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    const reserveDto: ReserveDto = {};
    reserveDto.passengerName = currentUser?.displayName;
    reserveDto.passengerEmail = currentUser?.userName;
    reserveDto.starttime = startDate;

    reserveDto.starttime.setHours(
      startTime.getHours(),
      startTime.getMinutes(),
      0
    );

    reserveDto.locationFrom = locationFrom;
    reserveDto.locationTo = locationTo;
    reserveDto.locationFromPlace = locationFromPlace;
    reserveDto.locationToPlace = locationToPlace;

    if (
      isAtHongKongAirport({
        lat: locationFromPlace?.geometry?.location?.lat()!,
        lng: locationFromPlace?.geometry?.location?.lng()!,
      })
    ) {
      reserveDto.orderTypeDto = { id: TYPE_OF_ARRIVAL_FROM_AIRPORT };
    } else if (
      isAtHongKongAirport({
        lat: locationToPlace?.geometry?.location?.lat()!,
        lng: locationToPlace?.geometry?.location?.lng()!,
      })
    ) {
      reserveDto.orderTypeDto = { id: TYPE_OF_DEPART_TO_AIRPORT };
    } else {
      reserveDto.orderTypeDto = { id: TYPE_OF_POINT_TO_POINT };
    }

    if (flightNo) {
      reserveDto.flightDto = {
        flight_no: flightNo,
        flight_date: reserveDto.starttime,
      };
    }
    reserveDto.passengerNum = passengerNum;
    reserveDto.luggNum = luggNum;
    reserveDto.fare = 350;
    reserveDto.createUser = "online";
    const confirmReserve = await dispatch(confirmReserveDto(reserveDto));
    navigate("/booking");
  };
  if (!currentUser) {
    return <HomeNewPage />;
  } else {
    return (
      <div className="main-wrapper">
        <div className="hero-sec-main">
          <header
            className={classNames({
              "header header-two": true,
              "header-fixed": scrollPosition >= 150,
            })}
          >
            <div className="header-two-top">
              <div className="container">
                <div className="header-top-items">
                  <ul className="header-address">
                    <li>Welcome to Bonni Travel！</li>
                  </ul>
                  <div className="header-top-right d-flex align-items-center">
                    <div className="header-top-flag-drops d-flex align-items-center">
                      <div className="header-top-drpodowns me-3"></div>
                    </div>
                    <div className="header-top-social-links">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa-brands fa-whatsapp"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <nav className="navbar navbar-expand-lg header-nav">
                <div className="navbar-header">
                  <a id="mobile_btn" href="javascript:void(0);">
                    <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </a>
                  <Link className="navbar-brand logo" to={`/home`}>
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Logo"
                      style={{ height: "35px" }}
                    />
                  </Link>
                  <Link className="navbar-brand logo-small" to={`/home`}>
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="Logo"
                      style={{ height: "35px" }}
                    />
                  </Link>
                </div>
                <div className="main-menu-wrapper">
                  <div className="menu-header">
                    <Link className="menu-logo" to={`/home`}>
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="Logo"
                        style={{ height: "35px" }}
                      />
                    </Link>
                    <a
                      id="menu_close"
                      className="menu-close"
                      href="javascript:void(0);"
                    >
                      <i className="fas fa-times"></i>
                    </a>
                  </div>
                  <ul className="main-nav">
                    <li>
                      <Link className="nav-item nav-link" to={`/booking`}>
                        立即預約
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-link"
                        to={`/bookinghistory`}
                      >
                        預約記錄
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-link"
                        to={`/termsandcondition`}
                      >
                        訂車流程及條款
                      </Link>
                    </li>
                    <li className="login-link">
                      <a href="#" onClick={clickLogout}>
                        登出
                      </a>
                    </li>
                  </ul>
                </div>
                <ul className="nav header-navbar-rht">
                  <li className="nav-item">
                    <a className="nav-link login-link" href="#">
                      <span>
                        <i className="bx bx-user me-2"></i>
                      </span>
                      {currentUser?.displayName}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link header-reg"
                      href="#"
                      onClick={clickLogout}
                    >
                      <span>
                        <i className="feather-power"></i>
                      </span>
                      登出
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </header>

          <section className="banner-section banner-sec-two banner-slider">
            <div className="container">
              <div className="home-banner">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="hero-sec-contents">
                      <div className="banner-title aos" data-aos="fade-down">
                        <h1>預訂機場接送服務</h1>
                        <p>約『旅朋友』來接送</p>
                      </div>
                      <div className="banner-form">
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={clickSubmitButton}
                        >
                          <div className="banner-search-list">
                            <div className="input-block">
                              <label>
                                <i className="bx bx-map"></i>上車地點
                              </label>
                              <div>
                                <div className="group-img">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="上車地點"
                                    value={locationFrom}
                                    ref={locationFromRef}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setLocationFrom(event.target.value);
                                    }}
                                    required
                                  />
                                  <GoogleMapAutoCompleteTextField
                                    textField={locationFromRef.current!}
                                    onTextChange={updateLocationFrom}
                                    key={key}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="input-block">
                              <label>
                                <i className="bx bx-map"></i>下車地點
                              </label>
                              <div>
                                <div className="group-img">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="下車地點"
                                    value={locationTo}
                                    ref={locationToRef}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setLocationTo(event.target.value);
                                    }}
                                    required
                                  />
                                  <GoogleMapAutoCompleteTextField
                                    textField={locationToRef.current!}
                                    onTextChange={updateLocationTo}
                                    key={key}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="input-block">
                              <label>
                                <i className="bx bx-calendar"></i>日期
                              </label>
                              <div className="date-widget">
                                <div className="group-img">
                                  <Calendar
                                    className="w-100"
                                    inputClassName="form-control"
                                    inputId="startDate"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.value!)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="input-block">
                              <label>
                                <i className="bi bi-clock"></i>
                                時間
                              </label>
                              <div className="date-widget">
                                <div className="group-img">
                                  <Calendar
                                    className="w-100"
                                    inputClassName="form-control"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.value!)}
                                    timeOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="input-block-btn">
                            <button className="btn btn-primary" type="submit">
                              <i className="bx bx-search-alt me-2"></i> 立即預約
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
