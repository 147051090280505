export default function AuthFooter() {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="log-footer">
        <div className="container-fluid">
          <div className="copyright">
            <div className="copyright-text">
              <p>
                &copy; COPYRIGHT {year} BONNI TRAVEL & LIMOUSINE SERVICES
                LIMITED
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
