import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { User } from "../entity/user";
import { getUserDetails, login } from "../service/authService";

export interface UserState {
  currentUser: User | null;
  access_token: string | null;
  loading: string;
  currentRequestId: string | undefined;
  error: SerializedError | null;
}

const initialState: UserState = {
  currentUser: localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : null,
  access_token: localStorage.getItem("access_token"),
  loading: "idle",
  currentRequestId: undefined,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.currentUser = null;
      state.access_token = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("currentUser");
      state.loading = "idle";
      state.currentRequestId = undefined;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(login.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.access_token = action.payload.access_token;
          localStorage.setItem("access_token", state.access_token);
          state.currentRequestId = undefined;
          console.log("reducer:" + state.access_token);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          console.log("login rejected");

          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getUserDetails.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentUser = action.payload;
          localStorage.setItem(
            "currentUser",
            JSON.stringify(state.currentUser)
          );
          state.currentRequestId = undefined;
          console.log("currentUser:" + state.currentUser);
        }
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { logout } = authSlice.actions;

export default authSlice.reducer;
