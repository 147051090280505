import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { ReserveDto } from "../entity/reserve-dto";
import { saveReserveDto } from "../service/reservationService";

export interface ReservationState {
  currentReserveDto: ReserveDto | null;
  confirmedReserveDto: ReserveDto | null;
  loading: string;
  currentRequestId: string | undefined;
  error: SerializedError | null;
}

const initialState: ReservationState = {
  currentReserveDto: null,
  confirmedReserveDto: null,
  loading: "idle",
  currentRequestId: undefined,
  error: null,
};

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    confirmReserveDto: (state, action) => {
      state.currentReserveDto = action.payload;
      state.loading = "idle";
      state.currentRequestId = undefined;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveReserveDto.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(saveReserveDto.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.confirmedReserveDto = action.payload;
          state.currentReserveDto = null;
          state.currentRequestId = undefined;
          console.log("saveReserveDto:" + state.confirmedReserveDto);
        }
      })
      .addCase(saveReserveDto.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { confirmReserveDto } = reservationSlice.actions;

export default reservationSlice.reducer;
