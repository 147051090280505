import { APIProvider, Map, useMap } from "@vis.gl/react-google-maps";
import { GOOGLE_MAP_API_KEY } from "../util/constant";
import { useEffect, useState } from "react";
import { duration } from "moment";

export default function GoogleMapComponent({
  from,
  to,
  key,
  setDuration,
}: {
  from?: string;
  to?: string;
  key: any;
  setDuration?: any;
}) {
  const map = useMap();
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService | null>(null);
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer | null>(null);

  useEffect(() => {
    if (!map) return;
    setDirectionsService(new google.maps.DirectionsService());
    const options = {
      map: map,
    };
    setDirectionsRenderer(new google.maps.DirectionsRenderer(options));
  }, [map]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer || !from || !to) return;
    if (setDuration) {
      setDuration("");
    }
    directionsService
      .route({
        origin: from,
        destination: to,
        travelMode: google.maps.TravelMode["DRIVING"],
      })
      .then((response) => {
        if (response && response) {
          console.log(response);
          directionsRenderer.setDirections(response);
          if (setDuration) {
            setDuration(response.routes[0]?.legs[0]?.duration?.text);
          }
        }
      })
      .catch((e) => console.log("Directions request failed due to " + e));
  }, [directionsService, directionsRenderer, from, to]);
  return (
    <Map
      style={{ width: "100%", height: "200px" }}
      defaultCenter={{ lat: 22.3193, lng: 114.1694 }}
      defaultZoom={10}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
    />
  );
}
