import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { ReserveDto } from "../../entity/reserve-dto";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import moment from "moment";
import { confirmReserveDto } from "../../redux/reservationSlice";
import { useNavigate } from "react-router-dom";
import PaypalComponenet from "../util/paypalComponent";
import FileUploader from "../util/fileUploader";
import { saveReserveDto } from "../../service/reservationService";
import fpsLogo from "../../fps_qr_code.jpeg";
import BookingStepper from "./bookingStepper";
import icon01 from "../../image/payment-method-01.svg";
import "../../scss/pages/booking.scss";
import "../../scss/pages/details-page.scss";
import FareComponent from "./fareComponent";
import SoepayComponenet from "../util/soepayComponent";

export default function BookingRedirectFromPaymentGateway() {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const currentReserveDto = sessionStorage.getItem("reserveDto")
      ? JSON.parse(sessionStorage.getItem("reserveDto")!)
      : null;
    const orderId = sessionStorage.getItem("orderId")!;
    console.log("orderId:" + orderId);
    if (currentReserveDto && orderId) {
      sessionStorage.removeItem("orderId");
      sessionStorage.removeItem("reserveDto");
      onApprove(currentReserveDto, orderId);
    }
  }, [sessionStorage]);

  const onApprove = async (currentReserveDto: ReserveDto, orderId: string) => {
    try {
      const response = await fetch(
        `/api/payment-service/soepay/checkout-order/${orderId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      const responseJson = await response.json();
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      if (
        responseJson?.data?.status === "COMPLETED" &&
        responseJson?.data?.paymentId
      ) {
        console.log("Capture result");
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        // return actions.restart();
        const reserveDto = {
          ...currentReserveDto,
          paymentStatus: true,
          paymentGatewayRef: responseJson?.data?.paymentId,
        };
        onPaymentSuccess(reserveDto);
      } else {
        alert(`Sorry, your transaction could not be processed...`);
        navigate("/booking");
      }
    } catch (error) {
      console.error(error);
      alert(`Sorry, your transaction could not be processed...${error}`);
      navigate("/booking");
    }
  };

  const onPaymentSuccess = async (reserveDto: ReserveDto) => {
    setLoading(true);

    dispatch(confirmReserveDto(reserveDto));
    const confirmReserve = await dispatch(saveReserveDto(reserveDto!))
      .unwrap()
      .then((reserveDto: ReserveDto) => {
        setLoading(false);
        navigate("/bookingresult/" + reserveDto.id);
      });
  };

  return (
    <div className="main-wrapper">
      <div className="container text-center">
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="warning" />
        </div>
      </div>
    </div>
  );
}
