import "../../scss/pages/loginPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../entity/user";
import { getUserDetails, login } from "../../service/authService";
import { RootState, useAppDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import { Button, Form, Spinner, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSaveResetPwdTokenMutation } from "../../service/accountService";

export default function ForgetPasswordPage() {
  const [userName, setUserName] = useState("");
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [
    resetPwdToken, // This is the mutation trigger
    { isLoading: isUpdating, error }, // This is the destructured mutation result
  ] = useSaveResetPwdTokenMutation();
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    const user: User = {};
    user.userName = userName;
    const status = resetPwdToken(user)
      .unwrap()
      .then((fulfilled: any) => {
        navigate("/resetpassword");
      })
      .catch((rejected: any) => {
        setToastMessage("密碼重設驗證碼時出現問題，請重新重設");
        setShowToast(true);
      });
  };

  return (
    <>
      <h1>忘記密碼？</h1>

      <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
        <div className="input-block form-floating">
          <Form.Control
            type="email"
            placeholder="電郵地址"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserName(event.target.value);
            }}
            required
          />

          <label className="form-label">
            電郵地址 <span className="text-danger">*</span>
          </label>
        </div>

        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>

        <Button
          type="submit"
          disabled={isUpdating}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          {isUpdating ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            "找回密碼"
          )}
        </Button>
      </Form>
    </>
  );
}
