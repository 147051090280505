import classNames from "classnames";
import icon01 from "../../image/booking-head-icon-01.svg";
import icon04 from "../../image/booking-head-icon-04.svg";
import icon05 from "../../image/booking-head-icon-05.svg";
export default function BookingStepper({ step }: { step: number }) {
  return (
    <div className="booking-wizard-head">
      <div className="row align-items-center">
        <div className="col-xl-7 col-lg-7">
          <div className="booking-head-title">
            <h4>規劃行程</h4>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5">
          <div className="booking-wizard-lists">
            <ul>
              <li
                className={classNames({
                  active: step == 1 || step == 2 || step == 3,
                  activated: step == 2 || step == 3,
                })}
              >
                <span>
                  <img src={icon01} alt="Booking Icon" />
                </span>
                <h6>預約</h6>
              </li>

              <li
                className={classNames({
                  active: step == 2 || step == 3,
                  activated: step == 3,
                })}
              >
                <span>
                  <img src={icon04} alt="Booking Icon" />
                </span>
                <h6>付款</h6>
              </li>
              <li
                className={classNames({
                  active: step == 3,
                  activated: step == 3,
                })}
              >
                {" "}
                <span>
                  <img src={icon05} alt="Booking Icon" />
                </span>
                <h6>成功預約</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
