export class OrderType {
  id?: number;
  label?: string;
  group?: number;
  color?: string;
}
export const TYPE_OF_DEPART_TO_AIRPORT = 1;
export const TYPE_OF_CHINA_HK_DEPART_TO_AIRPORT = 4;
export const TYPE_OF_ARRIVAL_FROM_AIRPORT = 6;
export const TYPE_OF_POINT_TO_POINT = 12;
