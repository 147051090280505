import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

export default function GoogleMapAutoCompleteTextField({
  textField,
  onTextChange,
}: {
  textField: HTMLInputElement;
  onTextChange?: any;
  key: any;
}) {
  const placesLibrary = useMapsLibrary("places");

  const [autocomplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const options = {
    componentRestrictions: { country: "hk" },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };
  useEffect(() => {
    if (!placesLibrary || !textField) return;
    console.log("placesLibrary");
    console.log(placesLibrary);
    console.log(textField);
    // when placesLibrary is loaded, the library can be accessed via the
    // placesLibrary API object
    setAutoComplete(new placesLibrary.Autocomplete(textField, options));
  }, [placesLibrary]);

  useEffect(() => {
    if (!autocomplete) return;
    autocomplete.addListener("place_changed", function () {
      var place = autocomplete.getPlace();
      console.log("place:");
      console.log(place);

      if (place) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // Do anything you like with what was entered in the ac field.
        console.log("You entered: " + place.name);
        if (onTextChange) {
          onTextChange(place);
        }
      }
    });
  }, [autocomplete]);
  return <></>;
}
