import { SetStateAction, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FileDto } from "../../entity/file-dto";

export default function FileUploader({
  label,
  onUploadSuccess,
}: {
  label: string;
  onUploadSuccess: any;
}) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadedFilename, setUploadedFilename] = useState("");

  // On file select (from the pop up)
  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the state
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      console.log("Uploading file...");

      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        const result = await fetch("/files/handleFileUpload/", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        const data = (await result.json()) as FileDto;
        if (onUploadSuccess) {
          onUploadSuccess(data.filename);
        }
        setUploadedFilename(data.filename!);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <Form.Group controlId="formFile" className="input-block">
        <Form.Label>{label}</Form.Label>
        <Form.Control type="file" onChange={onFileChange} />
      </Form.Group>
      <Row>
        <Col>
          {uploadedFilename && (
            <img
              src={"/files/" + uploadedFilename}
              style={{ maxWidth: "150px", maxHeight: "150px" }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
