import { useEffect } from "react";
import logo from "../image/icon-white.svg";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                  style={{ height: "100px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>
                      &copy; {year} BONNI TRAVEL & LIMOUSINE SERVICES LIMITED.
                      All Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
