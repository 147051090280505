import { useEffect } from "react";
import Footer from "../footer";
import Header from "../header";
import AOS from "aos";

export default function TermsAndCondition() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">訂車流程及條款</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-policy">
                <h6 data-aos="fade-down" className="aos pb-4">
                  訂車流程：
                </h6>
                <ul data-aos="fade-down" className="aos">
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    選擇日期和時間
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    確定起點和目的地
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    確認乘客總人數
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    確認行李件數和大小
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    提供接待客人的姓名和聯絡方式
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    若需機場接送，請提供航班資訊
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    預付車費並保留收據
                  </li>
                </ul>
                <p data-aos="fade-down" className="aos">
                  此流程旨在確保您的行程安排順利，感謝您的合作。
                </p>

                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">預訂：</span>{" "}
                  豪華轎車必須至少提前48小時預訂。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">聯繫方式：</span>{" "}
                  修改、取消或查詢服務可通過服務熱線（+852 3483
                  1516）及電郵（booking@bonnitravel.com）
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">服務範圍：</span>{" "}
                  提供往返香港國際機場的豪華轎車接送服務、點對點接送，往返於指定的目的地，除禁區外。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">等待時間：</span>{" "}
                  預訂送機或指定時間接機
                  *免費等候時間為15分鐘，如需延長等候時間，需客人同意，並將從第16分鐘起按每小時港幣200元收費。
                </p>
                <p data-aos="fade-down" className="aos">
                  按照航班預計到達時間（ETA）接機
                  *免費等候時間為60分鐘，以航班實際到達時間計算。如需延長等候時間，需客人同意，並將從第61分鐘起按每小時港幣200元收費。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">深夜附加費：</span>{" "}
                  時間由00:01至05:59期間,
                  所有服務將額外收取港幣130元。包括機場接送機服務，點對點接送服務(備注
                  ： 接機服務以當時機場管理局所顯示的到港時間為準)。
                </p>
                <p data-aos="fade-down" className="aos">
                  備註：
                  如您的航班原定到港時間為早上06:00或之後，但最終提前至早上06:00前到達，或原定於晚上24:00之前到達，但實際延遲至24:00之後（以香港機場管理局提供的最新資料為準），我們將補收港幣$130凌晨附加費。請注意，該費用僅在實際情況發生時適用，並將由我們的專業授權司機直接向您收取並轉交回公司。感謝您的理解與配合，敬請留意。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">行李存放條款：</span>{" "}
                  行李必須安全地固定在車內行李箱中。4座位轎車的行李容量為2個標準機艙行李箱，尺寸為（22''
                  X 12'' X
                  30''），6人座位轎車的行李容量為4個標準機艙行李箱，尺寸為（22''
                  X 12'' X
                  30''）。若客人需額外增加行李件數，為確保安全，我們只能在合理情況下進行安排。每件額外行李將收取港幣30元附加費，上限為兩件，並授權司機代收此費用。感謝您的理解與配合。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">中途加站：</span> 同區內中途加站
                  *需額外支付港幣100元起，費用以路程實際長短計算。所有中途加站的要求須在預訂車輛時提出，臨時通知司機恕不受理。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">行駛路線：</span>{" "}
                  司機有權選擇最合適的行車路線，包括經過隧道或選擇避開交通擁堵的路段。根據一般情況，每程往返機場的所需時間約為35至60分鐘。然而，此時間不包括交通擁堵或突發的交通事故，因此我們建議每位乘客提前預留足夠的交通時間。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">機場接待免費服務簡介：</span>{" "}
                  當航班抵達後，司機將在專車候車室恭候每位貴賓。乘客從接機大堂 A
                  或 B 出口向右行，即可抵達專車候車室。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">付費接機大堂出口等候服務：</span>{" "}
                  如客人要求司機在機場接機大堂A或B出口等候，我們亦能提供接機服務，在航班到達時，司機會按照當時航班停泊閘出口
                  A 或 B 區外，持客人名牌等候乘客60分鐘，該服務只需另支附港幣
                  100 元，如需此服務，請在訂車時確認。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">乘客注意事項：</span>{" "}
                  每次機場接送服務前約一小時，我們的系統將自動發送司機聯絡資訊，請留意
                  WhatsApp 或短信內容，以便順利聯繫司機。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">更改服務：</span>{" "}
                  每次服務客人可享有一次免費更改資料的權利，之後每次更改資料將收取港幣100元行政費用，所有更改必須在預訂時間的24小時前完成。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">取消服務：</span>{" "}
                  如需取消服務,請於服務開始前至少48小時提出通知及確認，否則所支付的費用不予退還。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">客人缺席：</span>{" "}
                  如客人在服務時間未出現，所支付的費用不予退還。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">車型及更換：</span>{" "}
                  提供服務的車型將取決於供應情況而定，我們有保留更換車輛的權利。如客人需要特定車輛類型，將收取額外港幣50元費用，並需要在預訂前通知。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">服務暫停條款：</span>{" "}
                  在八號或以上颱風信號或黑色暴雨警告生效期間，服務將暫停，並在信號解除後的2小時內恢復服務。暫停期間，客人所支付的費用可轉為下次訂車使用或全數退款，因此公司不對所做成的任何損失負責。
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">條款更改權利：</span>{" "}
                  公司保留隨時更改、延長或終止優惠及修訂條款的權利，恕不另行通知。如有任何與優惠有關的爭議，公司保留最終決定權。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
