import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import reservationReducer from "./reservationSlice";

import { useDispatch } from "react-redux";
import { reservationService } from "../service/reservationService";
import { accountService } from "../service/accountService";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    reserve: reservationReducer,

    [reservationService.reducerPath]: reservationService.reducer,
    [accountService.reducerPath]: accountService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(reservationService.middleware)
      .concat(accountService.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
