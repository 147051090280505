import { useEffect, useState } from "react";
import {
  FareType,
  ZONE_A,
  ZONE_B,
  ZONE_C,
  ZONE_D,
  zoneIdList,
} from "../util/constant";
import { District, isTunnelFareRequired } from "../../entity/district";
import {
  TYPE_OF_ARRIVAL_FROM_AIRPORT,
  TYPE_OF_DEPART_TO_AIRPORT,
} from "../../entity/orderType";
import { CAR_TYPE_MPV } from "../../entity/carTypeDto";
import { WAIT_AT_ID_TERMINAL_A_OR_B } from "../../entity/waitAtDto";

export default function FareComponent({
  locationFrom,
  locationTo,
  districtFrom,
  districtTo,
  startTime,
  onFareChange,
  inFareItems,
  orderTypeId,
  carTypeId,
  luggNum,
  waitAtId,
}: {
  locationFrom: string;
  locationTo: string;
  districtFrom?: District;
  districtTo?: District;
  startTime: Date;
  onFareChange?: any;
  inFareItems?: FareType;
  orderTypeId?: number;
  carTypeId?: number;
  luggNum?: number;
  waitAtId?: number;
}) {
  const [fareItems, setFareItems] = useState<FareType>({});

  useEffect(() => {
    console.log("update fare");
    if (inFareItems) {
      let tempTotalFare = 0;
      if (inFareItems.fare) {
        tempTotalFare += inFareItems.fare;
      }
      if (inFareItems.tunnelFare) {
        tempTotalFare += inFareItems.tunnelFare;
      }
      if (inFareItems.midnightFare) {
        tempTotalFare += inFareItems.midnightFare;
      }
      if (inFareItems.mustmpvFare) {
        tempTotalFare += inFareItems.mustmpvFare;
      }
      if (inFareItems.extraFare) {
        tempTotalFare += inFareItems.extraFare;
      }
      if (inFareItems.waitingFare) {
        tempTotalFare += inFareItems.waitingFare;
      }
      setFareItems({ ...inFareItems, totalFare: tempTotalFare });
    } else {
      console.log("update fare: " + districtFrom);
      let tempFare = 0;
      let tempFareItems: FareType = {};
      if (locationFrom || locationTo) {
        tempFareItems.fare = 0;
        let zone = null;
        if (orderTypeId === TYPE_OF_ARRIVAL_FROM_AIRPORT && districtTo?.id) {
          zone = zoneIdList[districtTo.id];
        } else if (
          orderTypeId === TYPE_OF_DEPART_TO_AIRPORT &&
          districtFrom?.id
        ) {
          zone = zoneIdList[districtFrom.id];
        }
        console.log(`orderType: ${orderTypeId} zone: ${zone}`);
        if (zone === ZONE_A) {
          tempFareItems.fare = 380;
        } else if (zone === ZONE_B) {
          tempFareItems.fare = 430;
        } else if (zone === ZONE_C || zone === ZONE_D) {
          tempFareItems.fare = 480;
        }
        tempFare += tempFareItems.fare;
      }
      if (
        startTime &&
        ((startTime.getHours() > 0 && startTime.getHours() < 5) ||
          (startTime.getHours() === 0 && startTime.getMinutes() >= 1) ||
          (startTime.getHours() === 5 && startTime.getMinutes() <= 59))
      ) {
        tempFareItems.midnightFare = 130;
        tempFare += tempFareItems.midnightFare;
      }
      /*if (
        orderTypeId == TYPE_OF_ARRIVAL_FROM_AIRPORT ||
        orderTypeId == TYPE_OF_DEPART_TO_AIRPORT
      ) {
        if (districtFrom?.id) {
          console.log("districtFrom:" + districtFrom.id);
          if (isTunnelFareRequired(districtFrom.id!)) {
            tempFareItems.tunnelFare = 100;
            tempFare += tempFareItems.tunnelFare;
          }
        }
        console.log("districtTo:" + districtTo?.id);

        if (districtTo?.id && !tempFareItems.tunnelFare) {
          console.log("districtTo:" + districtTo.id);
          if (isTunnelFareRequired(districtTo.id!)) {
            tempFareItems.tunnelFare = 100;
            tempFare += tempFareItems.tunnelFare;
          }
        }
      }*/
      if (carTypeId === CAR_TYPE_MPV) {
        tempFareItems.mustmpvFare = 50;
        tempFare += tempFareItems.mustmpvFare;
      }
      if (luggNum && luggNum > 4) {
        tempFareItems.extraFare = (luggNum - 4) * 30;
        tempFare += tempFareItems.extraFare;
      }
      if (waitAtId && waitAtId === WAIT_AT_ID_TERMINAL_A_OR_B) {
        tempFareItems.waitingFare = 100;
        tempFare += tempFareItems.waitingFare;
      }
      tempFareItems.totalFare = tempFare;
      setFareItems(tempFareItems);
    }
  }, [
    locationFrom,
    locationTo,
    startTime,
    inFareItems,
    districtFrom,
    districtTo,
    orderTypeId,
    carTypeId,
    luggNum,
    waitAtId,
  ]);

  useEffect(() => {
    if (fareItems && onFareChange) {
      onFareChange(fareItems);
    }
  }, [fareItems]);
  return (
    <>
      <div className="booking-sidebar-card">
        <div className="booking-sidebar-head">
          <h5>費用</h5>
        </div>
        <div className="booking-sidebar-body">
          <div className="booking-vehicle-rates">
            <ul>
              <li>
                <div className="rental-charge">
                  <h6>行程費用</h6>
                </div>
                <h5>${fareItems.fare}</h5>
              </li>
              {fareItems.tunnelFare! > 0 && (
                <li>
                  <h6>隧道費</h6>
                  <h5>+${fareItems.tunnelFare}</h5>
                </li>
              )}
              {fareItems.midnightFare! > 0 && (
                <li>
                  <h6>凌晨附加費</h6>
                  <h5>+${fareItems.midnightFare}</h5>
                </li>
              )}
              {fareItems.mustmpvFare! > 0 && (
                <li>
                  <h6>6人大型豪華商務轎車</h6>
                  <h5>+${fareItems.mustmpvFare}</h5>
                </li>
              )}
              {fareItems.extraFare! > 0 && (
                <li>
                  <h6>額外行李附加費</h6>
                  <h5>+${fareItems.extraFare}</h5>
                </li>
              )}
              {fareItems.waitingFare! > 0 && (
                <li>
                  <h6>付費接機大堂出口等候服務</h6>
                  <h5>+${fareItems.waitingFare}</h5>
                </li>
              )}
              <li className="total-rate">
                <h6>小計</h6>
                <h5>${fareItems.totalFare}</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="total-rate-card">
        <div className="vehicle-total-price">
          <h5>合計</h5>
          <span>${fareItems.totalFare}</span>
        </div>
      </div>
    </>
  );
}
