import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function RedirectLoginPage() {
  const clickLoginButton = () => {
    const successUrl = encodeURIComponent(
      `${window.location.protocol}//${window.location.host}/home`
    );
    window.location.href = `/auth/login?successUrl=${successUrl}`;
  };
  useEffect(() => {
    window.location.href = `/login/oauth2`;
  }, []);
  return (
    <>
      <h1>會員登入</h1>
      <div className="text-center dont-have">
        <Button
          type="button"
          onClick={clickLoginButton}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          密碼登入
        </Button>
      </div>
      <div className="text-center dont-have">
        還不是會員嗎？ &nbsp;
        <Link to={`/register`}>現在加入會員</Link>
      </div>
    </>
  );
}
