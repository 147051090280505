import { Link, useNavigate } from "react-router-dom";
import logo from "../image/icon-white.svg";
import userIcon from "../image/user-icon.svg";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { logout } from "../redux/authSlice";
import { NavDropdown } from "react-bootstrap";
import { click } from "@testing-library/user-event/dist/click";

export default function Header() {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clickLogout = async () => {
    let params = new URLSearchParams();
    const response = await fetch("/logout", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "include",
      body: params,
    });
    window.location.href = "/login/oauth2";
  };
  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <a id="mobile_btn" href="javascript:void(0);">
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </a>
              <Link className="navbar-brand logo" to={`/home`}>
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                  style={{ height: "35px" }}
                />
              </Link>
              <Link className="navbar-brand logo-small" to={`/home`}>
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                  style={{ height: "35px" }}
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link className="menu-logo" to={`/home`}>
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                    style={{ height: "35px" }}
                  />
                </Link>
                <a
                  id="menu_close"
                  className="menu-close"
                  href="javascript:void(0);"
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <ul className="main-nav">
                {/* <li className="has-submenu">
                  <a href="#">
                    Blog <i className="fas fa-chevron-down"></i>
                  </a>
                  <ul className="submenu">
                    <li>
                      <a href="blog-list.html">Blog List</a>
                    </li>
                    <li>
                      <a href="blog-grid.html">Blog Grid</a>
                    </li>
                    <li>
                      <a href="blog-details.html">Blog Details</a>
                    </li>
                  </ul>
                </li> */}
                {!!currentUser && (
                  <>
                    <li>
                      <Link
                        className="nav-item nav-link active"
                        to={`/booking`}
                      >
                        立即預約
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-link"
                        to={`/bookinghistory`}
                      >
                        預約記錄
                      </Link>
                    </li>
                  </>
                )}

                <li>
                  <Link className="nav-item nav-link" to={`/termsandcondition`}>
                    訂車流程及條款
                  </Link>
                </li>
                {!!currentUser && (
                  <li className="login-link">
                    <a href="#" onClick={clickLogout}>
                      登出
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              {/* <li className="nav-item dropdown logged-item noti-nav noti-wrapper">
                <a
                  href="#"
                  className="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                >
                  <span className="bell-icon">
                    <img src="assets/img/icons/bell-icon.svg" alt="Bell" />
                  </span>
                  <span className="badge badge-pill"></span>
                </a>
                <div className="dropdown-menu notifications">
                  <div className="topnav-dropdown-header">
                    <span className="notification-title">Notifications</span>
                    <a href="javascript:void(0)" className="clear-noti">
                      Clear All{" "}
                    </a>
                  </div>
                  <div className="noti-content">
                    <ul className="notification-list">
                      <li className="notification-message">
                        <a href="#">
                          <div className="media d-flex">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <img
                                className="avatar-img rounded-circle"
                                alt="User Image"
                                src="assets/img/profiles/avatar-01.jpg"
                              />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                <span className="noti-title">
                                  Jonathan Doe{" "}
                                </span>{" "}
                                has booked{" "}
                                <span className="noti-title">your service</span>
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  4 mins ago
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="#">
                          <div className="media d-flex">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <img
                                className="avatar-img rounded-circle"
                                alt="User Image"
                                src="assets/img/profiles/avatar-03.jpg"
                              />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                <span className="noti-title">
                                  Julie Pennington
                                </span>{" "}
                                has booked{" "}
                                <span className="noti-title">your service</span>
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  6 mins ago
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="#">
                          <div className="media d-flex">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <img
                                className="avatar-img rounded-circle"
                                alt="User Image"
                                src="assets/img/profiles/avatar-02.jpg"
                              />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                <span className="noti-title">
                                  Tyrone Roberts
                                </span>{" "}
                                has booked{" "}
                                <span className="noti-title">your service</span>
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  8 mins ago
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="#">
                          <div className="media d-flex">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <img
                                className="avatar-img rounded-circle"
                                alt="User Image"
                                src="assets/img/profiles/avatar-04.jpg"
                              />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                <span className="noti-title">
                                  Patricia Manzi
                                </span>{" "}
                                has booked{" "}
                                <span className="noti-title">your service</span>
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  12 mins ago
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="notification-message">
                        <a href="#">
                          <div className="media d-flex">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <img
                                className="avatar-img rounded-circle"
                                alt="User Image"
                                src="assets/img/profiles/avatar-01.jpg"
                              />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                <span className="noti-title">Jonathan Doe</span>{" "}
                                has booked{" "}
                                <span className="noti-title">your service</span>
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  4 mins ago
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="topnav-dropdown-footer">
                    <a href="#">View all Notifications</a>
                  </div>
                </div>
              </li> */}
              {!!currentUser && (
                <li className="nav-item dropdown has-arrow logged-item">
                  <NavDropdown
                    title={
                      <>
                        <span>
                          <i className="bx bx-user me-2 text-white"></i>
                        </span>
                        <span className="user-text">
                          {currentUser?.displayName}
                        </span>
                      </>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="#" onClick={clickLogout}>
                      <i className="feather-power"></i> 登出
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}
