import { useState } from "react";
import { useResetPwdMutation } from "../../service/accountService";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Toast } from "react-bootstrap";
import { PasswordResetToken } from "../../entity/passwordResetToken";

export default function ResetPasswordPage() {
  const [validated, setValidated] = useState(false);
  const [token, setToken] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [
    resetPwdAction, // This is the mutation trigger
    { isLoading: isUpdating, error }, // This is the destructured mutation result
  ] = useResetPwdMutation();

  const clickSubmitButton = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    if (pwd !== confirmPwd) {
      setToastMessage("確認密碼錯誤，請重新設定密碼");
      setShowToast(true);
      return;
    }
    const passwordResetToken: PasswordResetToken = {};
    passwordResetToken.newPassword = pwd;
    passwordResetToken.token = token;
    const status = resetPwdAction(passwordResetToken)
      .unwrap()
      .then((fulfilled: any) => {
        navigate("/login");
      })
      .catch((rejected: any) => {
        setToastMessage("密碼重設時出現問題，請重新重設密碼");
        setShowToast(true);
      });
  };

  return (
    <>
      <h1>重設密碼</h1>

      <Form onSubmit={clickSubmitButton} noValidate validated={validated}>
        <h6 className="mt-4 mb-4">驗證碼已發送，請查看電郵</h6>
        <Form.Group className="input-block form-floating" controlId="userName">
          <Form.Control
            type="text"
            placeholder="驗證碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setToken(event.target.value);
            }}
            required
          />
          <Form.Label>
            驗證碼 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group className="input-block form-floating" controlId="pwd">
          <Form.Control
            type="password"
            placeholder="密碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            密碼 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group
          className="input-block form-floating"
          controlId="confirmPwd"
        >
          <Form.Control
            type="password"
            placeholder="確認密碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            確認密碼 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>

        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
        <Button
          type="submit"
          disabled={isUpdating}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          {isUpdating ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            "重設密碼"
          )}
        </Button>
      </Form>
    </>
  );
}
