export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "";
export type Coordinates = {
  lat: number;
  lng: number;
};
const haversineDistance = (coords1: Coordinates, coords2: Coordinates) => {
  const toRad = (value: number) => (value * Math.PI) / 180;

  const lat1 = coords1.lat;
  const lon1 = coords1.lng;
  const lat2 = coords2.lat;
  const lon2 = coords2.lng;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance; // Distance in kilometers
};
export const isAtHongKongAirport = (coords: Coordinates) => {
  const hongKongAirportCoords = { lat: 22.308, lng: 113.9185 };
  const distance = haversineDistance(coords, hongKongAirportCoords);
  const threshold = 4; // 1 kilometer

  return distance <= threshold;
};
export type FareType = {
  fare?: number; //"0.00"
  tunnelFare?: number; //"0.00"
  midnightFare?: number;
  stopFare?: number;
  parkingFare?: number;
  mustmpvFare?: number;
  extraFare?: number;
  totalFare?: number;
  waitingFare?: number;
};

export const ZONE_A = "A";
export const ZONE_B = "B";
export const ZONE_C = "C";
export const ZONE_D = "D";

export const zoneIdList: { [key: number]: string } = {
  1: ZONE_A,
  2: ZONE_B, //	灣仔區	Wan Chai District
  3: ZONE_B, //東區	Eastern District
  4: ZONE_C, //南區	Southern District
  5: ZONE_A, //油尖旺	Yau Tsim Mong District
  6: ZONE_A, //深水埗	Sham Shui Po District
  7: ZONE_A, //九龍城	Kowloon City District
  8: ZONE_A, //	黃大仙	Wong Tai Sin District
  9: ZONE_A, //	觀塘	Kwun Tong District
  10: ZONE_A, //	葵青	Kwai Tsing District
  11: ZONE_A, //	荃灣	Tsuen Wan District
  12: ZONE_B, //	屯門	Tuen Mun District
  13: ZONE_C, //	元朗	Yuen Long District
  14: ZONE_C, //	北區	North District
  15: ZONE_B, //	大埔	Tai Po District
  16: ZONE_B, //	沙田	Sha Tin District
  17: ZONE_C, //	西貢	Sai Kung District
  18: ZONE_A, // 離島	Islands District
  19: ZONE_B, //	山頂	CENTRAL & WESTERN DISTRICT
};
