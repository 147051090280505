import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../entity/user";
import { Token } from "../entity/token";

export const login = createAsyncThunk(
  "auth/login",
  async (loginData: User, { rejectWithValue }) => {
    let params = new URLSearchParams();
    params.append("username", loginData.userName!);
    params.append("password", loginData.pwd!);
    params.append("grant_type", "password");
    params.append("client_id", "account");
    params.append("client_secret", "AVK15Tb6Nd4MNFRi4XLRZizEOV8oYtw4");
    const response = await fetch("/auth/login", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "include",
      body: params,
    });
    if (!response.ok) {
      return rejectWithValue(await response.json());
    } else {
      return (await response.json()) as Token;
    }

    // let token = new Token();
    // token.access_token = "abc";
    // return token;
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async () => {
    const response = await fetch("/api/accountService/getUserDetails", {
      method: "GET",
      headers: {
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      return null;
    } else {
      return (await response.json()) as User;
    }
    // let token = new Token();
    // token.access_token = "abc";
    // return token;
  }
);
