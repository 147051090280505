import { useState } from "react";
import { User } from "../../entity/user";
import { useSaveUserMutation } from "../../service/accountService";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Toast, ToastContainer } from "react-bootstrap";

export default function RegisterPage() {
  const [validated, setValidated] = useState(false);
  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [
    saveUser, // This is the mutation trigger
    { isLoading: isUpdating, error }, // This is the destructured mutation result
  ] = useSaveUserMutation();

  const clickRegisterButton = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    if (pwd !== confirmPwd) {
      setToastMessage("確認密碼錯誤，請重新設定密碼");
      setShowToast(true);
      return;
    }

    const user: User = {};
    user.userName = userName;
    user.email = userName;
    user.pwd = pwd;
    user.displayName = displayName;
    user.phoneNo = phoneNo;
    const savedUser = saveUser(user)
      .unwrap()
      .then((fulfilled: any) => {
        navigate("/login");
      })
      .catch((rejected: any) => {
        setToastMessage("註冊時出現問題，請重新註冊");
        setShowToast(true);
      });
  };

  return (
    <>
      <h1>註冊會員</h1>

      <Form onSubmit={clickRegisterButton} noValidate validated={validated}>
        <Form.Group className="input-block form-floating" controlId="userName">
          <Form.Control
            type="email"
            placeholder="電郵地址"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserName(event.target.value);
            }}
            required
          />
          <Form.Label>
            電郵地址 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group className="input-block form-floating" controlId="pwd">
          <Form.Control
            type="password"
            placeholder="密碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            密碼 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group
          className="input-block form-floating"
          controlId="confirmPwd"
        >
          <Form.Control
            type="password"
            placeholder="確認密碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            確認密碼 <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group
          className="input-block form-floating"
          controlId="displayName"
        >
          <Form.Control
            type="text"
            placeholder="聯絡人姓名"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDisplayName(event.target.value);
            }}
            required
          />
          <Form.Label>聯絡人姓名</Form.Label>
        </Form.Group>
        <Form.Group className="input-block form-floating" controlId="phoneNo">
          <Form.Control
            type="text"
            placeholder="手機號碼"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneNo(event.target.value);
            }}
            required
          />
          <Form.Label>手機號碼</Form.Label>
        </Form.Group>

        <div className="input-block">
          <p>按[註冊]即表示您已同意並閱讀私隱政策及免責條款</p>
        </div>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
        <Button
          type="submit"
          disabled={isUpdating}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          {isUpdating ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            "註冊"
          )}
        </Button>
      </Form>
    </>
  );
}
