import "../../scss/pages/loginPage.scss";
import logo from "../../logo.svg";
import AuthFooter from "./authfooter";
import LoginPage from "./loginPage";
import RegisterPage from "./register";
import ForgetPasswordPage from "./forgetPassword";
import ResetPasswordPage from "./resetPassword";
import RedirectLoginPage from "./redirectLoginPage";

export default function AuthComponent({ page }: { page: string }) {
  return (
    <>
      <div className="main-wrapper login-body">
        <header className="log-header">
          <a href="index.html">
            <img
              className="img-fluid logo-dark"
              src={logo}
              alt="Logo"
              style={{ height: "35px" }}
            />
          </a>
        </header>

        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                {(!page || page === "login") && <RedirectLoginPage />}
                {page === "register" && <RegisterPage />}
                {page === "forgotpassword" && <ForgetPasswordPage />}
                {page === "resetpassword" && <ResetPasswordPage />}
              </div>
            </div>
          </div>
        </div>
        <AuthFooter></AuthFooter>
      </div>
    </>
  );
}
